import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const LegalHeaderStyles = styled.header`
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
  @media ${media.mediumMobile} {
    height: 70vh;
  }
  @media ${media.largeMobile} {
    height: 60vh;
  }
  section {
    width: 90%;
    margin: var(--auto);
    position: relative;
    z-index: 5;
    text-align: center;
    strong {
      display: block;
      font-weight: 600;
      color: var(--alt);
      @media ${media.md} {
        font-size: 1.5rem;
      }
    }
    h1 {
      color: var(--alt);
      margin: 0.25rem 0;
      padding: 0;
      font-size: 2.25rem;
      font-weight: 600;
      @media ${media.mediumMobile} {
        font-size: 2.5rem;
      }
      @media ${media.sm} {
        font-size: 2.85rem;
      }
      @media ${media.md} {
        font-size: 3rem;
      }
      @media ${media.lg} {
        font-size: 3.5rem;
      }
    }
  }
`

const LegalHeader = ({ title }) => {
  const i = true
  return (
    <LegalHeaderStyles>
      <section>
        <h1>{title}</h1>
      </section>
    </LegalHeaderStyles>
  )
}

export default LegalHeader
